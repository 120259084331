<template>
  <v-container>
    <v-card>
      <v-card-title>Email Statistik</v-card-title>
      <v-card-subtitle>
        <v-col lg="3" cols="6">
          <v-menu
            ref="dateMenu"
            v-model="dateMenu"
            :close-on-content-click="false"
            :return-value.sync="dates"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="datesText"
                label="Datumsbereich"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="dates" no-title scrollable range>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="dateMenu = false">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="$refs.dateMenu.save(dates)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-card-subtitle>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="stats"
          :items-per-page="200"
          :loading="loading"
          loading-text="Daten werden geladen..."
          hide-default-footer
        >
          <template v-slot:item.date="{ item }">
            {{ formatDate(item.date) }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { format, addMinutes, addDays } from 'date-fns'
import { de } from 'date-fns/locale'
import emailService from '@/services/email'
import debounce from 'lodash/debounce'

export default {
  name: 'MailingLists',
  data() {
    return {
      loading: false,
      stats: [],
      headers: [
        { text: 'Datum', value: 'date' },
        { text: 'Gesendet', value: 'delivered' },
        { text: 'Fehlgeschlagen', value: 'failed' },
        { text: 'Geöffnet', value: 'opened' },
      ],
      dates: [format(addDays(new Date(), -30), 'yyyy-MM-dd'), format(new Date(), 'yyyy-MM-dd')],
      dateMenu: false,
    }
  },
  computed: {
    period() {
      return `${this.duration}${this.resolution}`
    },
    datesText() {
      return this.dates.map(this.formatDate).join(' - ')
    },
  },
  methods: {
    formatDate(date) {
      date = new Date(date)
      return format(addMinutes(date, date.getTimezoneOffset()), 'dd.MM.yyyy', { locale: de })
    },
    fetchStatistics() {
      this.loading = true
      return emailService.getStats(this.dates[0], this.dates[1]).then(data => {
        this.stats = data
        this.loading = false
      }).catch(_ => {
        this.loading = false
      })
    },
    debouncedFetchStatistics: debounce(function () {
      this.stats = []
      this.fetchStatistics()
    }, 750),
  },
  watch: {
    dates(newVal) {
      if (this.dates.length !== 2) {
        return
      }
      this.debouncedFetchStatistics()
    },
  },
  mounted() {
    this.fetchStatistics()
  },
}
</script>

<style>
</style>
